/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = UNKNOWN, 1 = TWIST_DEVICES, 2 = TWIST_DEVICES_EXTRA, 3 = TWIST_GATEWAY, 4 = WIREPAS_GATEWAY, 5 = WIREPAS_GATEWAY_STACK, 6 = WIREPAS_DEVICES_STACK
 */
export enum FirmwareTypesEnum {
    UNKNOWN = 0,
    TWIST_DEVICES = 1,
    TWIST_DEVICES_EXTRA = 2,
    TWIST_GATEWAY = 3,
    WIREPAS_GATEWAY = 4,
    WIREPAS_GATEWAY_STACK = 5,
    WIREPAS_DEVICES_STACK = 6
}

