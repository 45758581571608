/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RoutineNodeConnectorModel { 
    alias: string;
    data?: object;
    type: RoutineNodeConnectorModelTypeEnum;
}
export enum RoutineNodeConnectorModelTypeEnum {
    ProductEventConnector = 'ProductEventConnector',
    ActivateSceneConnector = 'ActivateSceneConnector',
    GenericConnector = 'GenericConnector'
};



