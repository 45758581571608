/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Waiting, 1 = Installing, 10 = Finished, 100 = Error
 */
export enum FirmwareInstallationStatus {
    Waiting = 0,
    Installing = 1,
    Finished = 10,
    Error = 100
}

