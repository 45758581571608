/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Never, 1 = Simple, 2 = Advanced
 */
export enum RoutineVisibility {
    Never = 0,
    Simple = 1,
    Advanced = 2
}

