/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Offline, 1 = Online, 2 = Joining, 3 = Leaving, 4 = Error
 */
export enum DeviceOnlineStatusEnum {
    Offline = 0,
    Online = 1,
    Joining = 2,
    Leaving = 3,
    Error = 4
}

