/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Error, 1 = WaitingToAddDevices, 2 = ProgrammingLinks, 3 = AllDevicesProgrammed, 4 = RemovingLinks, 5 = WaitingToRemoveDevices
 */
export enum LifeCycleStatus {
    Error = 0,
    WaitingToAddDevices = 1,
    ProgrammingLinks = 2,
    AllDevicesProgrammed = 3,
    RemovingLinks = 4,
    WaitingToRemoveDevices = 5
}

