/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 = Sunrise, 2 = Sunset
 */
export enum ScheduleTriggerEvents {
    Sunrise = 1,
    Sunset = 2
}

